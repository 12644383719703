import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAgentBingoState } from './states/IAgentBingoState';
import AxiosService from '../../../common/services/https.service';
import { backend_url } from '../../../../utils/backend_routes';
import { AxiosError } from 'axios';
import { ITransferMoney } from '../../../common/models/ITransferMoney';

const initialState: IAgentBingoState = {
  myShops: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
  transferMoney: {
    status: false,
    message: '',
    code: 0,
    data: {
      status: false,
      message: '',
      code: 0,
    },
  },
  myCompnayCartella: {
    status: false,
    message: '',
    code: 0,
    data: null,
  },
};

export const getMyShopsAPI = createAsyncThunk(
  'getAgentBingoShopAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        `${backend_url.agentsRoute.bingo.getMyShops}`,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const transferMoneyAPI = createAsyncThunk(
  'transferMoneyAPI',
  async (data: ITransferMoney, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        `${backend_url.agentsRoute.bingo.transferMoney}`,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const getMyCompanyCartellaAPI = createAsyncThunk(
  'getMyCompanyAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        `${backend_url.agentsRoute.bingo.myCompanyCartella}`,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

const agentBingoSlice = createSlice({
  name: 'agentBingoSlice',
  initialState,
  reducers: {
    updateTransferMoneyStatus: (state, action) => {
      state.transferMoney.data = {
        status: false,
        message: '',
        code: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyShopsAPI.pending, (state: any) => {
        state.myShops.status = false;
        state.myShops.loading = true;
        state.myShops.data = {
          status: true,
          message: '',
        };
      })
      .addCase(getMyShopsAPI.fulfilled, (state: any, action: any) => {
        state.myShops.data = action.payload;
        state.myShops.loading = false;
        state.myShops.status = true;
      })
      .addCase(getMyShopsAPI.rejected, (state: any, action: any) => {
        state.myShops.status = false;
        state.myShops.loading = false;
        state.myShops.error = true;
        state.myShops.errorMessage = action.payload;
      })
      // my shop
      .addCase(transferMoneyAPI.pending, (state: any) => {
        state.transferMoney.status = false;
        state.transferMoney.loading = true;
        state.transferMoney.data = {
          status: false,
          message: '',
        };
      })
      .addCase(transferMoneyAPI.fulfilled, (state: any, action: any) => {
        state.transferMoney.data = action.payload;
        state.transferMoney.loading = false;
        state.transferMoney.status = true;
      })
      .addCase(transferMoneyAPI.rejected, (state: any, action: any) => {
        state.transferMoney.status = false;
        state.transferMoney.loading = false;
        state.transferMoney.error = true;
        state.transferMoney.errorMessage = action.payload;
      })
      // my company cartella
      .addCase(getMyCompanyCartellaAPI.pending, (state: any) => {
        state.myCompnayCartella.status = false;
        state.myCompnayCartella.loading = true;
        state.myCompnayCartella.data = {
          status: false,
          message: '',
        };
      })
      .addCase(getMyCompanyCartellaAPI.fulfilled, (state: any, action: any) => {
        state.myCompnayCartella.data = action.payload;
        state.myCompnayCartella.loading = false;
        state.myCompnayCartella.status = true;
      })
      .addCase(getMyCompanyCartellaAPI.rejected, (state: any, action: any) => {
        state.myCompnayCartella.status = false;
        state.myCompnayCartella.loading = false;
        state.myCompnayCartella.error = true;
        state.myCompnayCartella.errorMessage = action.payload;
      });
  },
});

export const { updateTransferMoneyStatus } = agentBingoSlice.actions;

export default agentBingoSlice.reducer;
