import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICompanyGameState } from "./states/ICompanyGameState";
import AxiosService from "../../../common/services/https.service";
import { AxiosError } from "axios";
import { backend_url } from "../../../../utils/backend_routes";
import { IAddCompanyGameDTO, IRemoveGameDTO } from "../../../common/models/IAddGame.model";

const initialState: ICompanyGameState = {
  addGame: {
    status: false,
    message: "",
    code: 0,
    data: {
      status: false,
      message: "",
      code: 0,
    },
  },
  removeGame: {
    status: false,
    message: "",
    code: 0,
    data: {
      status: false,
      message: "",
      code: 0,
    },
  },
  enabledGames: {
    status: false,
    message: "",
    code: 0,
    data: [],
  },
};

export const enabledGamesAPI = createAsyncThunk(
  "enabledGamesAPI",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.adminRoute.enabledGames
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const addGameAPI = createAsyncThunk(
  "addGameAPI",
  async (data: IAddCompanyGameDTO, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.superAdminRoute.addGame,
        data
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const removeGameAPI = createAsyncThunk(
  "removeGameAPI",
  async (data: IRemoveGameDTO, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.superAdminRoute.removeGame,
        data
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

const companyGameSlice = createSlice({
  name: "companyGameSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addGameAPI.pending, (state: any) => {
        state.addGame.status = false;
        state.addGame.loading = true;
        state.addGame.data = [];
      })
      .addCase(addGameAPI.fulfilled, (state: any, action: any) => {
        state.addGame.data = action.payload;
        state.addGame.loading = false;
        state.addGame.status = true;
      })
      .addCase(addGameAPI.rejected, (state: any, action: any) => {
        state.addGame.status = false;
        state.addGame.loading = false;
        state.addGame.error = true;
        state.addGame.errorMessage = action.payload;
      })
      //remove game
      .addCase(removeGameAPI.pending, (state: any) => {
        state.removeGame.status = false;
        state.removeGame.loading = true;
        state.removeGame.data = [];
      })
      .addCase(removeGameAPI.fulfilled, (state: any, action: any) => {
        state.removeGame.data = action.payload;
        state.removeGame.loading = false;
        state.removeGame.status = true;
      })
      .addCase(removeGameAPI.rejected, (state: any, action: any) => {
        state.removeGame.status = false;
        state.removeGame.loading = false;
        state.removeGame.error = true;
        state.removeGame.errorMessage = action.payload;
      })
      //enabled games
      .addCase(enabledGamesAPI.pending, (state: any) => {
        state.enabledCompanyGames.status = false;
        state.enabledCompanyGames.loading = true;
        state.enabledCompanyGames.data = [];
      })
      .addCase(enabledGamesAPI.fulfilled, (state: any, action: any) => {
        state.enabledCompanyGames.data = action.payload;
        state.enabledCompanyGames.loading = false;
        state.enabledCompanyGames.status = true;
      })
      .addCase(enabledGamesAPI.rejected, (state: any, action: any) => {
        state.enabledCompanyGames.status = false;
        state.enabledCompanyGames.loading = false;
        state.enabledCompanyGames.error = true;
        state.enabledCompanyGames.errorMessage = action.payload;
      });
  },
});

export default companyGameSlice.reducer;
