import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IReportState } from './states/IReportState';
import { ITIcketDataReport } from '../model/ITicketDataReport';
import AxiosService from '../../../common/services/https.service';
import { backend_url } from '../../../../utils/backend_routes';
import { AxiosError } from 'axios';
import { IGameResult } from '../model/IGameResult';

const initialState: IReportState = {
  ticketReport: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
  gameResults: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
};

export const ticketDateReportAPI = createAsyncThunk(
  'ticketDateReportAPI',
  async (data: ITIcketDataReport, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.adminRoute.keno.ticketReport,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const gameResultReportAPI = createAsyncThunk(
  'gameResultReportAPI',
  async (data: IGameResult, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.adminRoute.keno.gameResults,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

const reportSlice = createSlice({
  name: 'reportSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ticketDateReportAPI.pending, (state: any) => {
        state.ticketReport.status = false;
        state.ticketReport.loading = true;
        state.ticketReport.data = [];
      })
      .addCase(ticketDateReportAPI.fulfilled, (state: any, action: any) => {
        state.ticketReport = action.payload;
      })
      .addCase(ticketDateReportAPI.rejected, (state: any, action: any) => {
        state.ticketReport.status = false;
        state.ticketReport.loading = false;
        state.ticketReport.error = true;
        state.ticketReport.errorMessage = action.payload;
      })
      //game Results
      .addCase(gameResultReportAPI.pending, (state: any) => {
        state.gameResults.status = false;
        state.gameResults.loading = true;
        state.gameResults.data = [];
      })
      .addCase(gameResultReportAPI.fulfilled, (state: any, action: any) => {
        state.gameResults = action.payload;
      })
      .addCase(gameResultReportAPI.rejected, (state: any, action: any) => {
        state.gameResults.status = false;
        state.gameResults.loading = false;
        state.gameResults.error = true;
        state.gameResults.errorMessage = action.payload;
      });
  },
});

export default reportSlice.reducer;
