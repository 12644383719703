import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBingoShopState } from './states/IBingShopState';
import { IBingoShop } from '../models/IBIngoShop';
import AxiosService from '../../../common/services/https.service';
import { backend_url } from '../../../../utils/backend_routes';
import { AxiosError } from 'axios';
import { IUpdateBingoShop } from '../models/IUpdateBingoShop';
import { IUpdateCartella } from '../models/IUpdateCartella';
import { IBingoCard } from '../models/IBingoCard';
import { IBingoDeposit } from '../../../common/models/IBingoDeposit';
import { IBingoCashReportFormData } from '../models/IBingoCashReportFormData';

const initialState: IBingoShopState = {
  registerBingoShop: {
    status: false,
    message: '',
    code: 0,
    data: {
      status: true,
      message: '',
      code: 0,
    },
  },
  bingoShopsAgents: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
  parentBingoShops: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
  updateBingoShop: {
    status: false,
    message: '',
    code: 0,
    data: null,
  },
  cartella: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
  updateCartella: {
    status: false,
    message: '',
    code: 0,
    data: null,
  },
  searchBingoShop: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
  lockAndReleaseBingoShop: {
    status: false,
    message: '',
    code: 0,
    data: {
      status: false,
      message: '',
      code: 0,
    },
  },
  addDeposit: {
    status: false,
    message: '',
    code: 0,
    data: {
      status: false,
      message: '',
      code: 0,
    },
  },
  bingoCashReport: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
  gameReports: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
};

export const updateCartellaAPI = createAsyncThunk(
  'updateCartellaAPI',
  async (data: IUpdateCartella, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.adminRoute.bingo.updateCartella,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);
export const showCartellaAPI = createAsyncThunk(
  'showCartellaAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        `${backend_url.adminRoute.bingo.showCartella}`,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const getParentBingoShopAPI = createAsyncThunk(
  'getParentBingoShopAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.adminRoute.bingo.parentBingoShops,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const getBingoShopAgentsAPI = createAsyncThunk(
  'getBingoShopAgentsAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.adminRoute.bingo.bingoShopAgents,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

interface ISearchArg {
  q: string;
}
export const searchBingoShopAgentsAPI = createAsyncThunk(
  'searchBingoShopAgentsAPI',
  async (arg: ISearchArg, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        `${backend_url.adminRoute.bingo.searchBingo}?q=${arg.q}`,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

interface LockReleaseArgument {
  bingoShopId: number;
}
export const lockBingoShopAPI = createAsyncThunk(
  'lockBingoShopAPI',
  async (arg: LockReleaseArgument, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        `${backend_url.adminRoute.bingo.lockBingoShop}${arg.bingoShopId}`,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const registerBingoAgentAPI = createAsyncThunk(
  'registerBingoAgentAPI',
  async (data: IBingoShop, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.adminRoute.bingo.registerAgent,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

interface IUpdateArguments {
  id: number;
  data: IUpdateBingoShop;
}

export const updateBingoAgentAPI = createAsyncThunk(
  'updateAgentAPI',
  async (argument: IUpdateArguments, { rejectWithValue }) => {
    try {
      const response = await AxiosService().put(
        `${backend_url.adminRoute.bingo.updateBingoAgent}${argument.id}`,
        argument.data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const addDepositAPI = createAsyncThunk(
  'addDepositAPI',
  async (data: IBingoDeposit, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.adminRoute.bingo.addDeposit,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const bingoCashReportAPI = createAsyncThunk(
  'binoCashReportAPI',
  async (data: IBingoCashReportFormData, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.adminRoute.bingo.bingoCashReport,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const gameReportsAPI = createAsyncThunk(
  'gameReportsAPI',
  async (data: IBingoCashReportFormData, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        `${backend_url.adminRoute.bingo.gameReports}`,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

const bingoShopSlice = createSlice({
  name: 'bingoShopSlice',
  initialState,
  reducers: {
    changeUpdateStatusBingoAgent: (state, action) => {
      state.updateBingoShop = {
        ...state.updateBingoShop,
        status: action.payload,
      };
    },
    updateBingoShopRegistrationStatus: (state, action) => {
      state.registerBingoShop = {
        ...state.registerBingoShop,
        status: action.payload,
      };
    },
    updateCartella: (
      state,
      action: PayloadAction<{ cardIndex: number; updatedCard: IBingoCard }>,
    ) => {
      const { cardIndex, updatedCard } = action.payload;
      state.cartella.data[cardIndex] = updatedCard;
    },
    changeCartellaAfterEdit: (state, action) => {
      state.cartella.data = action.payload;
      state.updateCartella.status = false;
    },
    updateLockAndReleaseStatus: (state, action) => {
      state.lockAndReleaseBingoShop.data = {
        status: false,
        message: '',
        code: 0,
      };
    },

    updateDepositState: (state, action) => {
      state.addDeposit.data = {
        status: false,
        message: '',
        code: 0,
      };
    },
    updateCartellaState: (state, action) => {
      state.updateCartella.status = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerBingoAgentAPI.pending, (state: any) => {
        state.registerBingoShop.status = false;
        state.registerBingoShop.loading = true;
        state.registerBingoShop.data = {
          status: true,
          message: '',
        };
      })
      .addCase(registerBingoAgentAPI.fulfilled, (state: any, action: any) => {
        state.registerBingoShop.data = action.payload;
        state.registerBingoShop.loading = false;
        state.registerBingoShop.status = true;
      })
      .addCase(registerBingoAgentAPI.rejected, (state: any, action: any) => {
        state.registerBingoShop.status = false;
        state.registerBingoShop.loading = false;
        state.registerBingoShop.error = true;
        state.registerBingoShop.errorMessage = action.payload;
      })
      // bingo shops agents
      .addCase(getBingoShopAgentsAPI.pending, (state: any) => {
        state.bingoShopsAgents.status = false;
        state.bingoShopsAgents.loading = true;
        state.bingoShopsAgents.data = [];
      })
      .addCase(getBingoShopAgentsAPI.fulfilled, (state: any, action: any) => {
        state.bingoShopsAgents.data = action.payload;
        state.bingoShopsAgents.loading = false;
        state.bingoShopsAgents.status = true;
      })
      .addCase(getBingoShopAgentsAPI.rejected, (state: any, action: any) => {
        state.bingoShopsAgents.status = false;
        state.bingoShopsAgents.loading = false;
        state.bingoShopsAgents.error = true;
        state.bingoShopsAgents.errorMessage = action.payload;
      })
      // bingo shops agents
      .addCase(getParentBingoShopAPI.pending, (state: any) => {
        state.parentBingoShops.status = false;
        state.parentBingoShops.loading = true;
        state.parentBingoShops.data = [];
      })
      .addCase(getParentBingoShopAPI.fulfilled, (state: any, action: any) => {
        state.parentBingoShops.data = action.payload;
        state.parentBingoShops.loading = false;
        state.parentBingoShops.status = true;
      })
      .addCase(getParentBingoShopAPI.rejected, (state: any, action: any) => {
        state.parentBingoShops.status = false;
        state.parentBingoShops.loading = false;
        state.parentBingoShops.error = true;
        state.parentBingoShops.errorMessage = action.payload;
      })
      // Ubpdate bingo shops agents
      .addCase(updateBingoAgentAPI.pending, (state: any) => {
        state.updateBingoShop.status = false;
        state.updateBingoShop.loading = true;
        state.updateBingoShop.data = [];
      })
      .addCase(updateBingoAgentAPI.fulfilled, (state: any, action: any) => {
        state.updateBingoShop.data = action.payload;
        state.updateBingoShop.loading = false;
        state.updateBingoShop.status = true;
      })
      .addCase(updateBingoAgentAPI.rejected, (state: any, action: any) => {
        state.updateBingoShop.status = false;
        state.updateBingoShop.loading = false;
        state.updateBingoShop.error = true;
        state.updateBingoShop.errorMessage = action.payload;
      })
      // Ubpdate bingo shops agents
      .addCase(showCartellaAPI.pending, (state: any) => {
        state.cartella.status = false;
        state.cartella.loading = true;
        state.cartella.data = [];
      })
      .addCase(showCartellaAPI.fulfilled, (state: any, action: any) => {
        state.cartella.data = action.payload;
        state.cartella.loading = false;
        state.cartella.status = true;
      })
      .addCase(showCartellaAPI.rejected, (state: any, action: any) => {
        state.cartella.status = false;
        state.cartella.loading = false;
        state.cartella.error = true;
        state.cartella.errorMessage = action.payload;
      })
      // Ubpdate cartella API
      .addCase(updateCartellaAPI.pending, (state: any) => {
        state.updateCartella.status = false;
        state.updateCartella.loading = true;
        state.updateCartella.data = [];
      })
      .addCase(updateCartellaAPI.fulfilled, (state: any, action: any) => {
        state.updateCartella.data = action.payload;
        state.updateCartella.loading = false;
        state.updateCartella.status = true;
      })
      .addCase(updateCartellaAPI.rejected, (state: any, action: any) => {
        state.updateCartella.status = false;
        state.updateCartella.loading = false;
        state.updateCartella.error = true;
        state.updateCartella.errorMessage = action.payload;
      })
      // Search bingo shop API
      .addCase(searchBingoShopAgentsAPI.pending, (state: any) => {
        state.searchBingoShop.status = false;
        state.searchBingoShop.loading = true;
        state.searchBingoShop.data = [];
      })
      .addCase(
        searchBingoShopAgentsAPI.fulfilled,
        (state: any, action: any) => {
          state.searchBingoShop.data = action.payload;
          state.searchBingoShop.loading = false;
          state.searchBingoShop.status = true;
        },
      )
      .addCase(searchBingoShopAgentsAPI.rejected, (state: any, action: any) => {
        state.searchBingoShop.status = false;
        state.searchBingoShop.loading = false;
        state.searchBingoShop.error = true;
        state.searchBingoShop.errorMessage = action.payload;
      })
      // Lock and realse Lock bingo API
      .addCase(lockBingoShopAPI.pending, (state: any) => {
        state.lockAndReleaseBingoShop.status = false;
        state.lockAndReleaseBingoShop.loading = true;
        state.lockAndReleaseBingoShop.data = [];
      })
      .addCase(lockBingoShopAPI.fulfilled, (state: any, action: any) => {
        state.lockAndReleaseBingoShop.data = action.payload;
        state.lockAndReleaseBingoShop.loading = false;
        state.lockAndReleaseBingoShop.status = true;
      })
      .addCase(lockBingoShopAPI.rejected, (state: any, action: any) => {
        state.lockAndReleaseBingoShop.status = false;
        state.lockAndReleaseBingoShop.loading = false;
        state.lockAndReleaseBingoShop.error = true;
        state.lockAndReleaseBingoShop.errorMessage = action.payload;
      })
      // Add Deposit API
      .addCase(addDepositAPI.pending, (state: any) => {
        state.addDeposit.status = false;
        state.addDeposit.loading = true;
      })
      .addCase(addDepositAPI.fulfilled, (state: any, action: any) => {
        state.addDeposit.data = action.payload;
        state.addDeposit.loading = false;
        state.addDeposit.status = true;
      })
      .addCase(addDepositAPI.rejected, (state: any, action: any) => {
        state.addDeposit.status = false;
        state.addDeposit.loading = false;
        state.addDeposit.error = true;
        state.addDeposit.errorMessage = action.payload;
      })
      // Cash Report API
      .addCase(bingoCashReportAPI.pending, (state: any) => {
        state.bingoCashReport.status = false;
        state.bingoCashReport.loading = true;
      })
      .addCase(bingoCashReportAPI.fulfilled, (state: any, action: any) => {
        state.bingoCashReport.data = action.payload;
        state.bingoCashReport.loading = false;
        state.bingoCashReport.status = true;
      })
      .addCase(bingoCashReportAPI.rejected, (state: any, action: any) => {
        state.bingoCashReport.status = false;
        state.bingoCashReport.loading = false;
        state.bingoCashReport.error = true;
        state.bingoCashReport.errorMessage = action.payload;
      })
      // Game Report API
      .addCase(gameReportsAPI.pending, (state: any) => {
        state.gameReports.status = false;
        state.gameReports.loading = true;
        state.gameReports.data = [];
      })
      .addCase(gameReportsAPI.fulfilled, (state: any, action: any) => {
        state.gameReports.data = action.payload;
        state.gameReports.loading = false;
        state.gameReports.status = true;
      })
      .addCase(gameReportsAPI.rejected, (state: any, action: any) => {
        state.gameReports.status = false;
        state.gameReports.loading = false;
        state.gameReports.error = true;
        state.gameReports.errorMessage = action.payload;
      });
  },
});

export const {
  updateBingoShopRegistrationStatus,
  changeUpdateStatusBingoAgent,
  updateCartella,
  changeCartellaAfterEdit,
  updateLockAndReleaseStatus,
  updateDepositState,
  updateCartellaState,
} = bingoShopSlice.actions;
export default bingoShopSlice.reducer;
