import { IDashboardReport } from '../modules/common/models/IDashboardReport';
import { IFormElement } from '../modules/common/models/IFormElement';
import { ILanguage } from '../modules/common/models/ILanguage.model';
import { StepMap } from '../modules/common/models/IStepMap';

export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL as string;
export const BASE_URL = process.env.REACT_APP_API_URL as string;
export const BINGO_GAME_URL = process.env.REACT_APP_BINGO_GAME_URL as string;

export const CURRENCY = 'ETB';

export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const SELECTED_LANGUAGE = 'selected_language';

export const SUPPORTED_LANGUAGES: ILanguage[] = [
  {
    id: 1,
    name: 'አማርኛ',
    code: 'am',
  },
  {
    id: 2,
    name: 'Afaan Oromo',
    code: 'ao',
  },
  {
    id: 3,
    name: 'ትግርኛ',
    code: 'tr',
  },
  {
    id: 4,
    name: 'English',
    code: 'en',
  },
];

export const AGENT_REGISTRATION_STEP_INFORMATION: StepMap = {
  '0': {
    information: 'Personal Information',
    description: 'Your agents personal information',
  },
  '1': {
    information: 'System setup',
    description: 'Set up system settings',
  },
  '2': {
    information: 'System setup',
    description: 'Set up system settings',
  },
};

export const TRANSFER_MONEY_FORM: IFormElement[] = [
  {
    name: 'toShopId',
    label: 'To Shop',
    placeholder: 'Choose...',
    type: 'select',
    is_required: true,
    required_message: 'please select agent',
  },
  {
    name: 'amount',
    label: 'Amount Br',
    placeholder: 'Amount Br',
    type: 'number',
    is_required: true,
    required_message: 'please enter transfer amount',
  },
];

export const DEPOSIT_MONEY_FORM: IFormElement[] = [
  {
    name: 'amount',
    label: 'Amount Br',
    placeholder: 'Amount Br',
    type: 'number',
    is_required: true,
    required_message: 'please enter deposit amount',
  },
];
export const ADD_NEW_CASHIER: IFormElement[] = [
  {
    name: 'agentId',
    label: 'Select shop',
    placeholder: 'Choose...',
    type: 'select',
    is_required: true,
    required_message: 'please select agent',
  },
  {
    name: 'cashiers',
    label: 'Number of new cashiers to add',
    placeholder: 'Number of new cashiers to add',
    value: '1',
    type: 'text',
    is_required: true,
    required_message: 'please enter number cashier to add',
  },
  {
    name: 'password',
    label: 'Password',
    placeholder: 'Your password',
    type: 'password',
    is_required: true,
    required_message: 'please enter your your password',
  },
];

export const CHANGE_PASSWORD_FORM: IFormElement[] = [
  {
    name: 'username',
    label: '',
    placeholder: '',
    type: 'text',
    value: '',
    disable: true,
    is_required: false,
    required_message: '',
  },
  {
    name: 'newPassword',
    label: '',
    placeholder: 'New password',
    value: '',
    type: 'password',
    is_required: true,
    required_message: '',
  },
  {
    name: 'confirmPassword',
    label: '',
    placeholder: 'Confirm password',
    value: '',
    type: 'password',
    is_required: true,
    required_message: '',
  },
];

export const ADMIN_CHANGE_PASSWORD_FORM: IFormElement[] = [
  {
    name: 'currentPassword',
    label: 'Current password',
    placeholder: 'Current password',
    type: 'password',
    disable: true,
    is_required: true,
    required_message: 'Please enter current password',
  },
  {
    name: 'newPassword',
    label: 'New password',
    placeholder: 'New password',
    type: 'password',
    is_required: true,
    required_message: 'please enter new password',
  },
];

export const BINGO_GAME_REPORT_FORM: IFormElement[] = [
  {
    name: 'agentId',
    label: 'Select Shop/agent',
    placeholder: 'Select Shop/agent',
    type: 'select',
    is_required: false,
    required_message: 'please select agent',
  },
];

export const BINGO_AGENT_CASH_REPORT_FORM: IFormElement[] = [
  {
    name: 'planId',
    label: 'Select plan',
    placeholder: 'Choose...',
    type: 'select',
    options: [
      {
        value: 'today',
        label: 'Today',
      },
      {
        value: 'yesterday',
        label: 'Yesterday',
      },
      {
        value: 'lastweek',
        label: 'Last Week',
      },
    ],
    is_required: false,
    required_message: 'please select your plan',
  },
  {
    name: 'dateRange',
    label: 'From date - To date',
    placeholder: 'From date - To date',
    type: 'daterange',
    is_required: false,
    required_message: 'please select date range',
  },
  {
    name: 'agentId',
    label: 'Select agents',
    placeholder: 'Select agents',
    type: 'select',
    is_required: true,
    required_message: 'please select agent',
  },
];

export const AGENT_TICKET_DATA_REPORT_FORM: IFormElement[] = [
  {
    name: 'date',
    label: 'Date',
    placeholder: 'date',
    type: 'date',
    is_required: false,
    required_message: 'please select date',
  },
  {
    name: 'agentShopId',
    label: 'Select shop',
    placeholder: 'Choose...',
    type: 'select',
    options: [],
    is_required: true,
    required_message: 'please select shop',
  },

  {
    name: 'ticketType',
    label: 'Ticket Type',
    placeholder: 'All',
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'lost',
        label: 'Lost',
      },
      {
        value: 'won',
        label: 'Won',
      },
      {
        value: 'redeemed',
        label: 'Redeemed',
      },

      {
        value: 'canceled',
        label: 'Canceled',
      },
      {
        value: 'unclaimed',
        label: 'Unclaimed',
      },
    ],
    type: 'select',
    is_required: false,
    required_message: 'please select ticket',
  },
  {
    name: 'game',
    label: 'Game',
    placeholder: 'All',
    type: 'select',
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'keno',
        label: 'Keno',
      },
      {
        value: 'spin',
        label: 'Spin',
      },
      {
        value: 'do',
        label: 'Dog',
      },
      {
        value: 'coin',
        label: 'Coin',
      },
      {
        value: 'red_keno',
        label: 'Red Keno',
      },
    ],
    is_required: false,
    required_message: 'please select game',
  },
  {
    name: 'ticketId',
    label: 'Slip Id',
    placeholder: 'slip/ticket id',
    type: 'text',
    is_required: false,
    required_message: 'please enter slip',
  },
];

export const AGENT_GAME_RESULT_DATA_REPORT_FORM: IFormElement[] = [
  {
    name: 'date',
    label: 'Date',
    placeholder: 'date',
    type: 'date',
    is_required: false,
    required_message: 'please select date',
  },
  {
    name: 'agentShopId',
    label: 'Select shop',
    placeholder: 'Choose...',
    type: 'select',
    options: [],
    is_required: true,
    required_message: 'please select shop',
  },
  {
    name: 'gameNumber',
    label: 'Game Id',
    placeholder: '',
    type: 'text',
    is_required: false,
    required_message: 'please enter game id',
  },
  {
    name: 'game',
    label: 'Game',
    placeholder: 'All',
    type: 'select',
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'keno',
        label: 'Keno',
      },
      {
        value: 'spin',
        label: 'Spin',
      },
      {
        value: 'do',
        label: 'Dog',
      },
      {
        value: 'coin',
        label: 'Coin',
      },
      {
        value: 'red_keno',
        label: 'Red Keno',
      },
    ],
    is_required: false,
    required_message: 'please select game',
  },
];
export const AGENT_CASH_REPORT_FORM: IFormElement[] = [
  {
    name: 'planId',
    label: 'Select plan',
    placeholder: 'Choose...',
    type: 'select',
    options: [
      {
        value: 'today',
        label: 'Today',
      },
      {
        value: 'yesterday',
        label: 'Yesterday',
      },
      {
        value: 'lastweek',
        label: 'Last Week',
      },
    ],
    is_required: false,
    required_message: 'please select your plan',
  },
  {
    name: 'dateRange',
    label: 'From date - To date',
    placeholder: 'From date - To date',
    type: 'daterange',
    is_required: false,
    required_message: 'please select date range',
  },
  {
    name: 'masterAgentId',
    label: 'Select master agent',
    placeholder: 'Select master agent',
    type: 'select',
    is_required: false,
    required_message: 'please select master agent',
  },
  {
    name: 'agentId',
    label: 'Select agents',
    placeholder: 'Select agents',
    type: 'select',
    is_required: false,
    required_message: 'please select agent',
  },
];

export const DASHBOARD_REPORTS: IDashboardReport[] = [
  {
    id: 1,
    title: 'Payin',
    data: 0,
    color: '#00bac7',
    subTitle: '185 Tickets',
    currency: 'Br',
    icon: 'solar:money-bag-outline',
    iconColor: '#00bac7',
  },

  {
    id: 2,
    title: 'Payout',
    data: 0,
    color: '#00bac7',
    subTitle: '80 Tickets',
    currency: 'Br',
    icon: 'tabler:cash-register',
    iconColor: '#d82c5b',
  },
  {
    id: 3,
    title: 'unclaimed',
    data: 0,
    color: '#00bac7',
    subTitle: '4 Tickets',
    currency: 'Br',
    icon: 'solar:money-bag-outline',
    iconColor: '#d82c5b',
  },
  {
    id: 4,
    title: 'Net',
    data: 0.0,
    color: '#00bac7',
    subTitle: '666 Tickets',
    currency: 'Br',
    icon: 'solar:money-bag-outline',
    iconColor: '#00bac7',
  },
];

export const BINGO_DASHBOARD_REPORTS: IDashboardReport[] = [
  {
    id: 1,
    title: 'Total Income',
    data: 0,
    color: '#00bac7',
    currency: 'Br',
    icon: 'solar:money-bag-outline',
    iconColor: '#00bac7',
  },
  {
    id: 2,
    title: 'NET',
    data: 0,
    color: '#00bac7',
    currency: 'Br',
    icon: 'solar:money-bag-outline',
    iconColor: '#00bac7',
  },
  {
    id: 3,
    title: 'Deposit balance',
    data: 0.0,
    color: '#00bac7',
    currency: 'Br',
    icon: 'solar:money-bag-outline',
    iconColor: '#00bac7',
  },
  {
    id: 4,
    title: 'Total Games',
    data: 0,
    color: '#00bac7',
    icon: 'tabler:cash-register',
    iconColor: '#d82c5b',
  },
  {
    id: 5,
    title: 'Agents/Shops',
    data: 0,
    color: '#00bac7',
    icon: 'solar:money-bag-outline',
    iconColor: '#d82c5b',
  },
];

export const KENO_DASHBOARD_REPORTS: IDashboardReport[] = [
  {
    id: 1,
    title: 'PAYIN',
    data: 0,
    color: '#00bac7',
    currency: 'Br',
    icon: 'solar:money-bag-outline',
    iconColor: '#00bac7',
  },
  {
    id: 2,
    title: 'PAYOUT',
    data: 0,
    color: '#00bac7',
    currency: 'Br',
    icon: 'solar:money-bag-outline',
    iconColor: '#d82c5b',
  },
  {
    id: 3,
    title: 'UNCLAIMED',
    data: 0.0,
    color: '#00bac7',
    currency: 'Br',
    icon: 'solar:money-bag-outline',
    iconColor: '#d82c5b',
  },
  {
    id: 4,
    title: 'NET',
    data: 0,
    color: '#00bac7',
    icon: 'tabler:cash-register',
    iconColor: '#00bac7',
  },
];

export const TOTAL_SHOP_AND_AVERAGE: IDashboardReport[] = [
  {
    id: 5,
    title: 'Shop/Agents',
    data: 0,
    color: '#00bac7',
    subTitle: '',
    icon: 'solar:money-bag-outline',
    isShowProgress: true,
    progressColor: '#31CF80',
    progressText: '',
  },
  {
    id: 6,
    title: 'RTP/MARGIN',
    data: 0,
    color: '#00bac7',
    subTitle: '',
    icon: 'solar:money-bag-outline',
    isShowProgress: true,
    progressColor: '#D82C5B',
    progressText: '',
  },
];

export const CASHIER_CASH_REPORT_FORM: IFormElement[] = [
  ...AGENT_CASH_REPORT_FORM,
  {
    name: 'cashierId',
    label: 'Select Cashier',
    placeholder: 'Select Cashier',
    type: 'select',
    is_required: false,
    required_message: 'please select Cashier',
  },
];
export const VIEW_CASHIER_FORM: IFormElement[] = [
  {
    name: 'username',
    label: 'username',
    placeholder: 'username',
    type: 'text',
    disable: true,
    is_required: true,
    className: 'disabled-input',
    required_message: 'please enter agent name',
  },
  {
    name: 'agentId',
    label: 'Agent',
    placeholder: 'Agent',
    type: 'select',
    is_required: true,
    required_message: 'please select agent',
  },
  {
    name: 'cashiLimit',
    label: 'Cash limit',
    placeholder: 'cash limit',
    value: 18000,
    type: 'text',
    is_required: true,
    required_message: 'please enter agent name',
  },
  {
    name: 'cashierRoleId',
    label: 'Role',
    placeholder: 'Role',
    type: 'select',
    value: 1,
    is_required: true,
    required_message: 'please select cashier role',
  },
  {
    name: 'minimumStake',
    label: 'Default minimum stake',
    placeholder: 'Minimum stake',
    type: 'select',
    value: 1,
    is_required: true,
    required_message: 'please select minimum stake',
  },
];

export const VIEW_AGENT_FORM: IFormElement[] = [
  {
    name: 'username',
    label: 'username',
    placeholder: 'username',
    type: 'text',
    disable: true,
    is_required: true,
    className: 'disabled-input',
    required_message: 'please enter agent name',
  },
  {
    name: 'parentId',
    label: 'Master agent',
    placeholder: 'Master agent',
    type: 'select',
    is_required: true,
    required_message: 'please select master agent',
  },
  {
    name: 'name',
    label: 'Name',
    placeholder: 'Name',
    type: 'text',
    is_required: true,
    required_message: 'please enter agent name',
  },
  {
    name: 'address',
    label: 'Address',
    placeholder: 'Address',
    type: 'text',
    is_required: true,
    required_message: 'please enter agent name',
  },
  {
    name: 'phone',
    label: 'Mobile',
    placeholder: 'phone',
    type: 'text',
    is_required: true,
    required_message: 'please enter agent name',
  },
  {
    name: 'share',
    label: '% Share',
    placeholder: 'share',
    type: 'text',
    is_required: true,
    required_message: 'please enter agent name',
  },
  {
    name: 'gameNumberStarts',
    label: 'Keno game is starts from',
    placeholder: 'share',
    type: 'text',
    is_required: true,
    required_message: 'please enter agent name',
  },
  {
    name: 'oddTypeId',
    label: 'Odd Type',
    placeholder: 'Odd type',
    type: 'select',
    is_required: false,
    required_message: 'please select odd type',
  },
  {
    name: 'roleId',
    label: '',
    placeholder: 'role',
    type: 'select',
    is_required: false,
    required_message: 'please enter agent name',
  },
  {
    name: 'margin',
    label: '% RTP/Margin',
    placeholder: 'margin',
    type: 'text',
    value: 'agent',
    is_required: true,
    required_message: 'please enter agent name',
  },
  {
    name: 'maxWinAmountId',
    label: 'Max Win Amount',
    placeholder: 'Max win amount',
    type: 'select',
    is_required: true,
    required_message: 'please select max win amount',
  },
  {
    name: 'winningSequenceId',
    label: 'Winning Sequence',
    placeholder: 'margin',
    type: 'select',
    is_required: true,
    required_message: 'please enter agent name',
  },
];

export const VIEW_BINGO_AGENT_FORM: IFormElement[] = [
  {
    name: 'username',
    label: 'username',
    placeholder: 'username',
    type: 'text',
    disable: true,
    is_required: true,
    className: 'disabled-input',
    required_message: 'please enter agent name',
  },
  {
    name: 'parentId',
    label: 'Master agent',
    placeholder: 'Master agent',
    type: 'select',
    isMultipleSelect: false,
    is_required: true,
    required_message: 'please select master agent',
  },
  {
    name: 'name',
    label: 'Name',
    placeholder: 'Name',
    type: 'text',
    is_required: true,
    required_message: 'please enter agent name',
  },
  {
    name: 'address',
    label: 'Address',
    placeholder: 'Address',
    type: 'text',
    is_required: true,
    required_message: 'please enter agent name',
  },
  {
    name: 'deposit',
    label: 'Deposit',
    placeholder: 'Initial deposit',
    type: 'number',
    is_required: true,
    required_message: 'please enter initial deposit',
  },
  {
    name: 'phone',
    label: 'Mobile',
    placeholder: 'phone',
    type: 'text',
    is_required: true,
    required_message: 'please enter agent name',
  },
  {
    name: 'share',
    label: '% Share',
    placeholder: 'share',
    type: 'text',
    is_required: true,
    required_message: 'please enter share',
  },
];

export const AGENT_REGISTRATION_FIRST_STEP: IFormElement[] = [
  {
    name: 'name',
    label: 'Agent Name',
    placeholder: 'Your agents names',
    type: 'text',
    is_required: true,
    required_message: 'please enter your agent name',
  },
  {
    name: 'phone',
    label: 'Mobile',
    placeholder: 'Mobile number',
    type: 'text',
    is_required: true,
    required_message: 'please enter your agent mobile number',
  },
  {
    name: 'address',
    label: 'Address',
    placeholder: 'Agents address',
    type: 'text',
    is_required: true,
    required_message: 'please enter your agent address',
  },
];

export const BINGO_AGENT_REGISTRATION_SECOND_SETP_FORM: IFormElement[] = [
  {
    name: 'username',
    label: 'Username',
    placeholder: 'A unique username',
    type: 'text',
    is_required: true,
    required_message: 'please enter your username',
  },
  {
    name: 'deposit',
    label: 'Deposit',
    placeholder: 'Initial deposit',
    type: 'number',
    is_required: true,
    required_message: 'please enter initial deposit',
  },
  {
    name: 'cashiers',
    label: 'Cashiers',
    placeholder: 'Cashiers',
    type: 'text',
    value: '1',
    is_required: false,
    required_message: 'please enter number of casheir',
  },
];

export const AGENT_REGISTRATION_SECONDS_STEP: IFormElement[] = [
  {
    name: 'gameNumberStarts',
    label: 'Keno game Id starts from',
    placeholder: 'Game Id',
    value: '5000',
    type: 'text',
    is_required: false,
    required_message: 'please enter your agent name',
  },
  {
    name: 'cashiers',
    label: 'Cashiers',
    placeholder: 'Cashiers',
    type: 'text',
    value: '4',
    is_required: false,
    required_message: 'please enter number of casheir',
  },
  {
    name: 'maxWinAmountId',
    label: 'Max Win Amount',
    placeholder: 'Max Win Amount',
    type: 'select',
    is_required: false,
    required_message: 'please select max win amount',
  },
  {
    name: 'winningSequenceId',
    label: 'Winning sequence',
    placeholder: 'Winning sequence',
    type: 'select',
    is_required: false,
    required_message: 'please select winning sequence',
  },
];

export const AGENT_REGISTRATION_THIRD_STEP: IFormElement[] = [
  {
    name: 'username',
    label: 'Username',
    placeholder: 'A unique username',
    type: 'text',
    is_required: true,
    required_message: 'please enter your username',
  },
  {
    name: 'oddTypeId',
    label: 'Odd type',
    placeholder: 'Odd type',
    type: 'select',
    is_required: false,
    required_message: 'please select odd type',
  },
  {
    name: 'margin',
    label: 'Margin/RTP',
    placeholder: 'Margin',
    value: '8',
    type: 'text',
    is_required: false,
    required_message: 'please select margin',
  },
];

export const COMPANY_REGISTRATION_STEPS = [
  {
    title: 'Step 1',
    description: 'Company Registration',
  },
  {
    title: 'Step 2',
    description: 'Company Owner Registration',
  },
];

export const NEW_COMPANY_OWNER_FORM: IFormElement[] = [
  {
    name: 'ownerName',
    label: 'Owner name',
    placeholder: 'Owner name',
    type: 'text',
    is_required: true,
    required_message: 'please enter company owner name',
  },
  {
    name: 'phone',
    label: 'Phone name',
    placeholder: 'phone name',
    type: 'text',
    is_required: true,
    required_message: 'please enter company owner name',
  },
  {
    name: 'username',
    label: 'User name',
    placeholder: 'User name',
    type: 'text',
    is_required: true,
    required_message: 'please enter company owner user name',
  },
  {
    name: 'password',
    label: 'Password',
    placeholder: 'Password',
    type: 'password',
    is_required: true,
    required_message: 'password_error_message',
  },
];

export const ADD_NEW_COMPANY: IFormElement[] = [
  {
    name: 'companyName',
    label: 'Comapny name',
    placeholder: 'Comapny name',
    type: 'text',
    is_required: true,
    required_message: 'please enter company name',
  },
  {
    name: 'address',
    label: 'Address',
    placeholder: 'Address',
    type: 'text',
    is_required: true,
    required_message: 'please enter your company address',
  },
  {
    name: 'deposit',
    label: 'Deposit',
    placeholder: 'Depsit',
    type: 'number',
    is_required: true,
    required_message: 'please enter deposit',
  },
  {
    name: 'gameIds',
    label: 'Select game',
    placeholder: 'Select game',
    type: 'select',
    isMultipleSelect: true,
    is_required: true,
    required_message: 'please select game',
  },
];

export const VIEW_COMPANY_FORM: IFormElement[] = [
  {
    name: 'companyName',
    label: 'Comapny name',
    placeholder: 'Comapny name',
    type: 'text',
    is_required: true,
    required_message: 'please enter company name',
  },
  {
    name: 'address',
    label: 'Address',
    placeholder: 'Address',
    type: 'text',
    is_required: true,
    required_message: 'please enter your company address',
  },
  {
    name: 'deposit',
    label: 'Deposit',
    placeholder: 'Depsit',
    type: 'number',
    is_required: true,
    required_message: 'please enter deposit',
  },
];

export const ADD_CASHIER: IFormElement[] = [
  {
    name: 'shopId',
    label: 'Select shop',
    placeholder: 'choose...',
    type: 'select',
    is_required: true,
    required_message: 'please select shops',
  },
  {
    name: 'cashiersNumber',
    label: 'Number of new cashiers to add',
    placeholder: 'cashiers number',
    type: 'text',
    is_required: true,
    required_message: 'please enter number of cashiers',
  },
  {
    name: 'password',
    label: 'Password',
    placeholder: 'Password',
    type: 'password',
    is_required: true,
    required_message: 'password_error_message',
  },
];

export const ADD_GAME_FORM: IFormElement[] = [
  {
    name: 'gameIds',
    label: 'Select game',
    placeholder: 'Select game',
    type: 'select',
    isMultipleSelect: true,
    is_required: true,
    required_message: 'please select game',
  },
];
