import { lazy, Suspense, useEffect } from 'react';

import './styles/main.scss';
import {
  useAppDispatch,
  useAppSelector,
} from './store/redux-hooks/redux-hooks';
import GenericSpinner from './modules/common/components/GenericSwipper/GenericSpinner';
import { BrowserRouter } from 'react-router-dom';

import { decodeJWT } from './utils/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ACCESS_TOKEN } from './constants/constants';
import { getItemFromLocalStorage } from './modules/common/services/TokenService';
import { Games, Roles } from './utils/enums';
import {
  changeCurrentGameApp,
  changeCurrentRole,
  changeEnabledGames,
} from './slices/AuthenticationSlice';

const AsyncHomeRouterApp = lazy(() => import('./apps/HomeApp'));
const AsynSuperAdminRouterApp = lazy(() => import('./apps/SuperAdminApp'));
const AsyncAdminKenoApp = lazy(() => import('./apps/AdminKenoApp'));
const AsyncAgentKenoApp = lazy(() => import('./apps/AgentKenoApp'));
const AsyncAgentBingoApp = lazy(() => import('./apps/AgentBingoApp'));

const AsyncBingoAdminApp = lazy(() => import('./apps/AdminBingoApp'));
const loader = <GenericSpinner />;

const homeApp = (
  <div>
    <AsyncHomeRouterApp />
  </div>
);

const superAdminApp = (
  <div>
    <AsynSuperAdminRouterApp />
  </div>
);

const adminBingoApp = (
  <div>
    <AsyncBingoAdminApp />
  </div>
);

const companyOwnerApp = (
  <div>
    <AsyncAdminKenoApp />
  </div>
);

const agentKenoApp = (
  <div>
    <AsyncAgentKenoApp />
  </div>
);

const agentBingoApp = (
  <div>
    <AsyncAgentBingoApp />
  </div>
);

const queryClient = new QueryClient();

function App() {
  let router = null;
  const dispatch = useAppDispatch();

  const { isUserLoggedIn, currentRole, currentGameApp } = useAppSelector(
    (state) => state.AuthenticationSlice,
  );

  useEffect(() => {
    const accessTokenFromLocalStorage = getItemFromLocalStorage(ACCESS_TOKEN);
    if (accessTokenFromLocalStorage) {
      const { roleId, games } = decodeJWT(accessTokenFromLocalStorage);
      dispatch(changeCurrentRole(roleId));
      dispatch(
        changeCurrentGameApp(
          games.length === 1
            ? games[0]
            : games.find((game) => game === Games.KENO),
        ),
      );
      const uniqueGames = Array.from(new Set(games.map((game) => game)));
      console.log(uniqueGames);
      dispatch(changeEnabledGames(uniqueGames));
    }
  }, []);

  if (!isUserLoggedIn && currentRole === 0) {
    router = homeApp;
  } else {
    if (currentRole === Roles.superAdmin) {
      router = superAdminApp;
    } else if (currentRole === Roles.Admin) {
      if (currentGameApp === Games.BINGO) {
        router = adminBingoApp;
      } else if (currentGameApp === Games.KENO) {
        router = companyOwnerApp;
      }
    } else if (currentRole === Roles.Agent) {
      if (currentGameApp === Games.KENO) {
        router = agentKenoApp;
      } else if (currentGameApp === Games.BINGO) {
        router = agentBingoApp;
      }
    }
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Suspense fallback={loader}>{router}</Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
