import { jwtDecode } from 'jwt-decode';
import { SELECTED_LANGUAGE } from '../constants/constants';
import { IJWtPayload } from '../modules/common/models/IJwtPayload.model';

export const storeSelectedLanguage = (language: any) => {
  localStorage.setItem(SELECTED_LANGUAGE, JSON.stringify(language));
};

export const getSelectedLanguage = () => {
  const selectedLanguage = localStorage.getItem(SELECTED_LANGUAGE);
  return typeof selectedLanguage === 'string'
    ? JSON.parse(selectedLanguage)
    : null;
};

export const decodeJWT = (accessToken: string): IJWtPayload => {
  return jwtDecode(accessToken);
};

export function getEnumName<T extends { [key: number]: string }>(
  enumObj: T,
  value: number,
): string {
  return enumObj[value] || 'Unknown';
}

export const capitalize = (str: string) =>
  str.replace(/\b\w/g, (char) => char.toUpperCase());

export const getUniqueRandomNumbers = (
  min: number,
  max: number,
  count: number,
) => {
  const numbers = new Set();
  while (numbers.size < count) {
    numbers.add(Math.floor(Math.random() * (max - min + 1)) + min);
  }
  return Array.from(numbers);
};

export const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};
