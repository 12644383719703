import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IWinSequenceState } from "./states/IWinSequenceState";
import AxiosService from "../../../common/services/https.service";
import { backend_url } from "../../../../utils/backend_routes";
import { AxiosError } from "axios";

const initialState: IWinSequenceState = {
  winningSequences: {
    status: false,
    message: "",
    code: 0,
    data: [],
  },
};

export const getWinningSequenceAPI = createAsyncThunk(
  "getWinningSequenceAPI",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(backend_url.winningSequence);
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

const winningSequenceSlice = createSlice({
  name: "winningSequenceSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWinningSequenceAPI.pending, (state: any) => {
        state.winningSequences.status = false;
        state.winningSequences.loading = true;
        state.winningSequences.data = [];
      })
      .addCase(getWinningSequenceAPI.fulfilled, (state: any, action: any) => {
        state.winningSequences.data = action.payload;
        state.winningSequences.loading = false;
        state.winningSequences.status = true;
      })
      .addCase(getWinningSequenceAPI.rejected, (state: any, action: any) => {
        state.winningSequences.status = false;
        state.winningSequences.loading = false;
        state.winningSequences.error = true;
        state.winningSequences.errorMessage = action.payload;
      });
  },
});

export default winningSequenceSlice.reducer;
