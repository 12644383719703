import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAgentState } from './states/IAgentState';
import AxiosService from '../../../common/services/https.service';
import { AxiosError } from 'axios';
import { backend_url } from '../../../../utils/backend_routes';
import { IRegisterAgentFormData } from '../../../common/models/IRegisterAgentFormData';
import { IUpdateAgentShop } from '../../../common/models/IUpdateAgentShop.model';

const initialState: IAgentState = {
  registerAgent: {
    status: false,
    message: '',
    code: 0,
    data: null,
  },
  shops: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
  selectedAgent: {
    name: '',
    phone: '',
    address: '',
    gameNumberStarts: 0,
    margin: 0,
    maxWinAmount: 0,
    oddType: '',
    username: '',
    winningSequence: '',
    cashiers: 0,
  },
  parentAgents: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
  updateAgent: {
    status: false,
    message: '',
    code: 0,
    data: null,
  },
  companyDashboardReport: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
};

export const registerAgentAPI = createAsyncThunk(
  'registerAgentAPI',
  async (data: IRegisterAgentFormData, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.adminRoute.keno.registerAgent,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const getAgentAPI = createAsyncThunk(
  'getAgentAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.adminRoute.keno.agents,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const getParentAgentsAPI = createAsyncThunk(
  'getParentAgentsAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.adminRoute.keno.parentAgents,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

interface IUpdateArguments {
  id: number;
  data: IUpdateAgentShop;
}
export const updateAgentAPI = createAsyncThunk(
  'updateAgentAPI',
  async (argument: IUpdateArguments, { rejectWithValue }) => {
    try {
      const response = await AxiosService().put(
        `${backend_url.adminRoute.keno.updateAgent}${argument.id}`,
        argument.data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const companyDashboardReportAPI = createAsyncThunk(
  'getCompanyDashboardReportAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.adminRoute.keno.companyDashboardReport,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const agentSlice = createSlice({
  name: 'agentSlice',
  initialState,
  reducers: {
    changeSelectedAgent: (state, action) => {
      state.selectedAgent = action.payload;
    },
    updateAgentRegistartionStatus: (state, action) => {
      state.registerAgent = { ...state.registerAgent, status: action.payload };
    },
    updateAgentUpdateStatus: (state, action) => {
      state.updateAgent = { ...state.updateAgent, status: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerAgentAPI.pending, (state: any) => {
        state.registerAgent.status = false;
        state.registerAgent.loading = true;
        state.registerAgent.data = [];
      })
      .addCase(registerAgentAPI.fulfilled, (state: any, action: any) => {
        state.registerAgent.data = action.payload;
        state.registerAgent.loading = false;
        state.registerAgent.status = true;
      })
      .addCase(registerAgentAPI.rejected, (state: any, action: any) => {
        state.registerAgent.status = false;
        state.registerAgent.loading = false;
        state.registerAgent.error = true;
        state.registerAgent.errorMessage = action.payload;
      })
      // get agents
      .addCase(getAgentAPI.pending, (state: any) => {
        state.shops.status = false;
        state.shops.loading = true;
        state.shops.data = [];
      })
      .addCase(getAgentAPI.fulfilled, (state: any, action: any) => {
        state.shops.data = action.payload;
        state.shops.loading = false;
        state.shops.status = true;
      })
      .addCase(getAgentAPI.rejected, (state: any, action: any) => {
        state.shops.status = false;
        state.shops.loading = false;
        state.shops.error = true;
        state.shops.errorMessage = action.payload;
      })
      // get parent agents
      .addCase(getParentAgentsAPI.pending, (state: any) => {
        state.parentAgents.status = false;
        state.parentAgents.loading = true;
        state.parentAgents.data = [];
      })
      .addCase(getParentAgentsAPI.fulfilled, (state: any, action: any) => {
        state.parentAgents.data = action.payload;
        state.parentAgents.loading = false;
        state.parentAgents.status = true;
      })
      .addCase(getParentAgentsAPI.rejected, (state: any, action: any) => {
        state.parentAgents.status = false;
        state.parentAgents.loading = false;
        state.parentAgents.error = true;
        state.parentAgents.errorMessage = action.payload;
      })
      // update agent
      .addCase(updateAgentAPI.pending, (state: any) => {
        state.updateAgent.status = false;
        state.updateAgent.loading = true;
        state.updateAgent.data = [];
      })
      .addCase(updateAgentAPI.fulfilled, (state: any, action: any) => {
        state.updateAgent.data = action.payload;
        state.updateAgent.loading = false;
        state.updateAgent.status = true;
      })
      .addCase(updateAgentAPI.rejected, (state: any, action: any) => {
        state.updateAgent.status = false;
        state.updateAgent.loading = false;
        state.updateAgent.error = true;
        state.updateAgent.errorMessage = action.payload;
      })
      // company dashboard report
      .addCase(companyDashboardReportAPI.pending, (state: any) => {
        state.companyDashboardReport.status = false;
        state.companyDashboardReport.loading = true;
      })
      .addCase(
        companyDashboardReportAPI.fulfilled,
        (state: any, action: any) => {
          state.companyDashboardReport = action.payload;
          state.companyDashboardReport.loading = false;
        },
      )
      .addCase(
        companyDashboardReportAPI.rejected,
        (state: any, action: any) => {
          state.companyDashboardReport.status = false;
          state.companyDashboardReport.loading = false;
          state.companyDashboardReport.error = true;
          state.companyDashboardReport.errorMessage = action.payload;
        },
      );
  },
});

export const {
  changeSelectedAgent,
  updateAgentRegistartionStatus,
  updateAgentUpdateStatus,
} = agentSlice.actions;

export default agentSlice.reducer;
