export const backend_url = {
  signIn: '/auth/login',

  me: '/users/me',
  refreshToken: '/users/refreshToken',
  verifyAccount: '/users/verifyAccount',
  forgotPassword: '/users/forgotPassword',
  forgotPasswordVerification: '/users/forgotPasswordVerification',
  resetPassword: '/users/resetPassword',
  changePassword: '/users/changePassword',
  adminChangePassword: '/users/adminChangePassword',
  checkUserExistence: '/users/checkUserExistence',

  getRoles: '/roles',
  oddTypes: '/oddTypes',
  maxWinAmounts: '/maxWinAmounts',
  winningSequence: '/winningSequence',

  superAdminRoute: {
    games: '/games',
    companies: '/company',
    addGame: '/companyGames/addGame',
    removeGame: '/companyGames/removeGame',
    updateCompany: '/company/update/',
  },

  adminRoute: {
    company: '/company',
    keno: {
      registerAgent: '/agents/register',
      agents: '/agents',
      parentAgents: '/agents/parentAgents',
      updateAgent: '/agents/updateAgent/',
      addNewCashiers: '/cashiers/addNew',
      allCompanyCashiers: '/cashiers/allCompanyCashiers',
      getCashierRoles: '/cashiers/roles',
      minimumStakes: '/stakes/minimumStakes',
      ticketReport: '/admin/ticketReport',
      gameResults: '/admin/gameResults',
      companyDashboardReport: '/admin/dashboardReport',
    },
    bingo: {
      registerAgent: '/bingo/agent',
      bingoShopAgents: '/bingo/agent',
      parentBingoShops: '/bingo/agent/parentBingoShops',
      updateBingoAgent: '/bingo/agent/updateBingoAgent/',
      bingoCashiers: '/bingo/agent/cashiers',
      searchBingo: '/bingo/agent/search',
      showCartella: '/bingo/cartella',
      updateCartella: '/bingo/cartella/updateCartella',
      companyBingoDashboardReport: '/bingo/agent/companyBingoDashboardReport',
      lockBingoShop: '/bingo/agent/lockBingoShop/',
      addDeposit: '/bingo/agent/addDeposit',
      bingoCashReport: '/bingo/agent/bingoCashReport',
      gameReports: '/bingo/game/gameReports',
    },
    enabledGames: '/companyGames/enabledGames',
  },
  agentsRoute: {
    bingo: {
      agentBingoDashboardReport: '/bingo/agent/agentBingoDashboardReport',
      getMyShops: '/bingo/agent/getAgentBingoShops',
      transferMoney: '/bingo/agent/transferMoney',
      myCompanyCartella: '/bingo/agent/agentCartella',
    },
  },
};
