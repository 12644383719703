import { createSlice } from "@reduxjs/toolkit";
import { ICommonState } from "./states/ICommonState";

const initialState: ICommonState = {
  selectedLanguage: {
    id: 0,
    code: "",
    name: "",
  },
  showMenu: false,
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    changeLocale: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    onShowMenu: (state, action) => {
      state.showMenu = action.payload;
    },
  },
});

export const { changeLocale, onShowMenu } = commonSlice.actions;

export default commonSlice.reducer;
