import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICashierState } from "./states/ICashierState";
import { IAddNewCashier } from "../../../common/models/IAddNewCashier";
import AxiosService from "../../../common/services/https.service";
import { backend_url } from "../../../../utils/backend_routes";
import { AxiosError } from "axios";

const initialState: ICashierState = {
  addNewCashier: {
    status: false,
    message: "",
    code: 0,
  },
  allCompanyCashiers: {
    status: false,
    message: "",
    code: 0,
    data: [],
  },
  cashierRoles: {
    status: false,
    message: "",
    code: 0,
    data: [],
  },
};

export const addNewCashierAPI = createAsyncThunk(
  "addNewCashierAPI",
  async (data: IAddNewCashier, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.adminRoute.keno.addNewCashiers,
        data
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const getAllCompanyCashiersAPI = createAsyncThunk(
  "getAllCompanyCashiersAPI",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.adminRoute.keno.allCompanyCashiers
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const getCashierRolesAPI = createAsyncThunk(
  "getCashierRolesAPI",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.adminRoute.keno.getCashierRoles
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

const cashierSlice = createSlice({
  name: "cashierSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addNewCashierAPI.pending, (state: any) => {
        state.addNewCashier.status = false;
        state.addNewCashier.loading = true;
        state.addNewCashier.data = [];
      })
      .addCase(addNewCashierAPI.fulfilled, (state: any, action: any) => {
        state.addNewCashier.data = action.payload;
        state.addNewCashier.loading = false;
        state.addNewCashier.status = true;
      })
      .addCase(addNewCashierAPI.rejected, (state: any, action: any) => {
        state.addNewCashier.status = false;
        state.addNewCashier.loading = false;
        state.addNewCashier.error = true;
        state.addNewCashier.errorMessage = action.payload;
      })
      // call company cashiers
      .addCase(getAllCompanyCashiersAPI.pending, (state: any) => {
        state.allCompanyCashiers.status = false;
        state.allCompanyCashiers.loading = true;
        state.allCompanyCashiers.data = [];
      })
      .addCase(
        getAllCompanyCashiersAPI.fulfilled,
        (state: any, action: any) => {
          state.allCompanyCashiers.data = action.payload;
          state.allCompanyCashiers.loading = false;
          state.allCompanyCashiers.status = true;
        }
      )
      .addCase(getAllCompanyCashiersAPI.rejected, (state: any, action: any) => {
        state.allCompanyCashiers.status = false;
        state.allCompanyCashiers.loading = false;
        state.allCompanyCashiers.error = true;
        state.allCompanyCashiers.errorMessage = action.payload;
      })
      // get cashiers roles
      .addCase(getCashierRolesAPI.pending, (state: any) => {
        state.cashierRoles.status = false;
        state.cashierRoles.loading = true;
        state.cashierRoles.data = [];
      })
      .addCase(getCashierRolesAPI.fulfilled, (state: any, action: any) => {
        state.cashierRoles.data = action.payload;
        state.cashierRoles.loading = false;
        state.cashierRoles.status = true;
      })
      .addCase(getCashierRolesAPI.rejected, (state: any, action: any) => {
        state.cashierRoles.status = false;
        state.cashierRoles.loading = false;
        state.cashierRoles.error = true;
        state.cashierRoles.errorMessage = action.payload;
      });
  },
});

export default cashierSlice.reducer;
