import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IRoleState } from "./states/IRoleState";
import AxiosService from "../../../common/services/https.service";
import { AxiosError } from "axios";
import { backend_url } from "../../../../utils/backend_routes";

const initialState: IRoleState = {
  roles: {
    status: false,
    message: "",
    code: 0,
    data: [],
  },
};

export const getRoleAPI = createAsyncThunk(
  "getRoleAPI",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(backend_url.getRoles);
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

const roleSlice = createSlice({
  name: "roleSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoleAPI.pending, (state: any) => {
        state.roles.status = false;
        state.roles.loading = true;
        state.roles.data = [];
      })
      .addCase(getRoleAPI.fulfilled, (state: any, action: any) => {
        state.roles.data = action.payload;
        state.roles.loading = false;
        state.roles.status = true;
      })
      .addCase(getRoleAPI.rejected, (state: any, action: any) => {
        state.roles.status = false;
        state.roles.loading = false;
        state.roles.error = true;
        state.roles.errorMessage = action.payload;
      });
  },
});

export default roleSlice.reducer;
