import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IGameStates } from "./states/IGameState";
import AxiosService from "../../common/services/https.service";
import { AxiosError } from "axios";
import { backend_url } from "../../../utils/backend_routes";

const initialState: IGameStates = {
  games: {
    status: false,
    message: "",
    code: 0,
    data: [],
  },
};

export const getGamesAPI = createAsyncThunk(
  "getAgentAPI",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.superAdminRoute.games
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

const gameSlice = createSlice({
  name: "gameSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    // get agents
    builder
      .addCase(getGamesAPI.pending, (state: any) => {
        state.games.status = false;
        state.games.loading = true;
        state.games.data = [];
      })
      .addCase(getGamesAPI.fulfilled, (state: any, action: any) => {
        state.games.data = action.payload;
        state.games.loading = false;
        state.games.status = true;
      })
      .addCase(getGamesAPI.rejected, (state: any, action: any) => {
        state.games.status = false;
        state.games.loading = false;
        state.games.error = true;
        state.games.errorMessage = action.payload;
      });
  },
});

export default gameSlice.reducer;
