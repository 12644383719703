import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AxiosService from '../../common/services/https.service';
import { backend_url } from '../../../utils/backend_routes';
import { AxiosError } from 'axios';
import { ISuperAdminState } from './states/ISuperAdmin';
import { ICompany } from '../../common/models/ICompany.modle';

const initialState: ISuperAdminState = {
  registerCompany: {
    status: false,
    message: '',
    code: 0,
    data: null,
  },
  companies: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
  updateCompany: {
    status: false,
    message: '',
    code: 0,
    data: {
      id: 0,
      name: '',
      logo: '',
      deposit: 0,
      status: 0,
    },
  },
};

export const getCompaniesAPI = createAsyncThunk(
  'getCompaniesAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.superAdminRoute.companies,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const registerCompanyAPI = createAsyncThunk(
  'registerCompanyAPI',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.superAdminRoute.companies,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);
interface IUpdateArgs {
  companyId: number;
  data: ICompany;
}
export const updateCompanyAPI = createAsyncThunk(
  'updateCompanyAPI',
  async (args: IUpdateArgs, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        `${backend_url.superAdminRoute.updateCompany}${args.companyId}`,
        args.data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

const companySlice = createSlice({
  name: 'companySlice',
  initialState,
  reducers: {
    updateCompanyUpdateState: (state, action) => {
      state.updateCompany.status = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerCompanyAPI.pending, (state: any) => {
        state.registerCompany.status = false;
        state.registerCompany.loading = true;
        state.registerCompany.data = [];
      })
      .addCase(registerCompanyAPI.fulfilled, (state: any, action: any) => {
        state.registerCompany.data = action.payload;
        state.registerCompany.loading = false;
        state.registerCompany.status = true;
      })
      .addCase(registerCompanyAPI.rejected, (state: any, action: any) => {
        state.registerCompany.status = false;
        state.registerCompany.loading = false;
        state.registerCompany.error = true;
        state.registerCompany.errorMessage = action.payload;
      })
      //get companies
      .addCase(getCompaniesAPI.pending, (state: any) => {
        state.companies.status = false;
        state.companies.loading = true;
        state.companies.data = [];
      })
      .addCase(getCompaniesAPI.fulfilled, (state: any, action: any) => {
        state.companies.data = action.payload;
        state.companies.loading = false;
        state.companies.status = true;
      })
      .addCase(getCompaniesAPI.rejected, (state: any, action: any) => {
        state.companies.status = false;
        state.companies.loading = false;
        state.companies.error = true;
        state.companies.errorMessage = action.payload;
      })
      //update companies
      .addCase(updateCompanyAPI.pending, (state: any) => {
        state.updateCompany.status = false;
        state.updateCompany.loading = true;
        state.updateCompany.data = [];
      })
      .addCase(updateCompanyAPI.fulfilled, (state: any, action: any) => {
        state.updateCompany.data = action.payload;
        state.updateCompany.loading = false;
        state.updateCompany.status = true;
      })
      .addCase(updateCompanyAPI.rejected, (state: any, action: any) => {
        state.updateCompany.status = false;
        state.updateCompany.loading = false;
        state.updateCompany.error = true;
        state.updateCompany.errorMessage = action.payload;
      });
  },
});

export const { updateCompanyUpdateState } = companySlice.actions;
export default companySlice.reducer;
