import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AxiosService from '../services/https.service';
import { backend_url } from '../../../utils/backend_routes';
import { AxiosError } from 'axios';
import {
  IAdminChangePassword,
  IChangePassword,
} from '../models/IChangePassword';
import { IUserState } from './states/IUserState';
import { ICheckUserNameExistence } from '../models/ICheckUserNameExistence';

const initialState: IUserState = {
  user: {
    status: false,
    message: '',
    code: 0,
    data: {
      id: 0,
      name: '',
      phone: '',
      username: '',
      company: {
        id: 0,
        name: '',
        logo: '',
        status: 0,
        deposit: 0,
      },
      statusId: 0,
    },
  },
  changePassword: {
    status: false,
    message: '',
    loading: false,
    code: 0,
    data: null,
  },
  adminChangePassword: {
    status: false,
    message: '',
    code: 0,
    data: {
      status: false,
      message: '',
      code: 0,
    },
  },
  dashboardReport: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
  userNameExistence: {
    status: false,
    message: '',
    code: 0,
    data: {
      status: false,
      message: '',
      code: 0,
    },
  },
  agentBingoDashboardReport: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
};

export const meQuery = createAsyncThunk(
  'api/me',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(backend_url.me);
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const getCompanyBingoDashboardReportAPI = createAsyncThunk(
  'getBingoDashboardReportAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.adminRoute.bingo.companyBingoDashboardReport,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const adminChangePasswordAPI = createAsyncThunk(
  'adminChangePasswordAPI',
  async (data: IAdminChangePassword, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.adminChangePassword,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const changePasswordAPI = createAsyncThunk(
  'registerAgentAPI',
  async (data: IChangePassword, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.changePassword,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const checkUsernameExistenceAPI = createAsyncThunk(
  'checkUsernameExistenceAPI',
  async (data: ICheckUserNameExistence, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        backend_url.checkUserExistence,
        data,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

export const getAgentBingoDashboardReportAPI = createAsyncThunk(
  'getAgentBingoDashboardReportAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.agentsRoute.bingo.agentBingoDashboardReport,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

const UserSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    updatePasswordChangedStatus: (state, action) => {
      state.changePassword = {
        ...state.changePassword,
        status: action.payload || false,
      };
    },
    udpateAdminPasswordState: (state, action) => {
      state.adminChangePassword.data.status = false;
      state.adminChangePassword.data.message = '';
    },
    updateUserNameTakenState: (state, action) => {
      state.userNameExistence.status = false;
      state.userNameExistence.message = '';
      state.userNameExistence.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(meQuery.pending, (state: any) => {
        state.user.status = false;
        state.user.loading = true;
        state.user.data = [];
      })
      .addCase(meQuery.fulfilled, (state: any, action: any) => {
        state.user.data = action.payload;
        state.user.loading = false;
        state.user.status = true;
      })
      .addCase(meQuery.rejected, (state: any, action: any) => {
        state.user.status = false;
        state.user.loading = false;
        state.user.error = true;
        state.user.errorMessage = action.payload;
      })
      // change password
      .addCase(changePasswordAPI.pending, (state: any) => {
        state.changePassword.status = false;
        state.changePassword.loading = true;
        state.changePassword.data = [];
      })
      .addCase(changePasswordAPI.fulfilled, (state: any, action: any) => {
        state.changePassword.data = action.payload;
        state.changePassword.loading = false;
        state.changePassword.status = true;
      })
      .addCase(changePasswordAPI.rejected, (state: any, action: any) => {
        state.changePassword.status = false;
        state.changePassword.loading = false;
        state.changePassword.error = true;
        state.changePassword.errorMessage = action.payload;
      })
      // admin change password
      .addCase(adminChangePasswordAPI.pending, (state: any) => {
        state.adminChangePassword.status = false;
        state.adminChangePassword.loading = true;
        state.adminChangePassword.data = [];
      })
      .addCase(adminChangePasswordAPI.fulfilled, (state: any, action: any) => {
        state.adminChangePassword.data = action.payload;
        state.adminChangePassword.loading = false;
        state.adminChangePassword.status = true;
      })
      .addCase(adminChangePasswordAPI.rejected, (state: any, action: any) => {
        state.adminChangePassword.status = false;
        state.adminChangePassword.loading = false;
        state.adminChangePassword.error = true;
        state.adminChangePassword.errorMessage = action.payload;
      })
      //dahboard report
      .addCase(getCompanyBingoDashboardReportAPI.pending, (state: any) => {
        state.dashboardReport.status = false;
        state.dashboardReport.loading = true;
        state.dashboardReport.data = [];
      })
      .addCase(
        getCompanyBingoDashboardReportAPI.fulfilled,
        (state: any, action: any) => {
          state.dashboardReport.data = action.payload;
          state.dashboardReport.loading = false;
          state.dashboardReport.status = true;
        },
      )
      .addCase(
        getCompanyBingoDashboardReportAPI.rejected,
        (state: any, action: any) => {
          state.dashboardReport.status = false;
          state.dashboardReport.loading = false;
          state.dashboardReport.error = true;
          state.dashboardReport.errorMessage = action.payload;
        },
      )
      //user name existence
      .addCase(checkUsernameExistenceAPI.pending, (state: any) => {
        state.userNameExistence.status = false;
        state.userNameExistence.loading = true;
        state.userNameExistence.data = [];
      })
      .addCase(
        checkUsernameExistenceAPI.fulfilled,
        (state: any, action: any) => {
          state.userNameExistence.data = action.payload;
          state.userNameExistence.loading = false;
          state.userNameExistence.status = true;
        },
      )
      .addCase(
        checkUsernameExistenceAPI.rejected,
        (state: any, action: any) => {
          state.userNameExistence.status = false;
          state.userNameExistence.loading = false;
          state.userNameExistence.error = true;
          state.userNameExistence.errorMessage = action.payload;
        },
      )
      // user
      .addCase(getAgentBingoDashboardReportAPI.pending, (state: any) => {
        state.agentBingoDashboardReport.status = false;
        state.agentBingoDashboardReport.loading = true;
        state.agentBingoDashboardReport.data = [];
      })
      .addCase(
        getAgentBingoDashboardReportAPI.fulfilled,
        (state: any, action: any) => {
          state.agentBingoDashboardReport.data = action.payload;
          state.agentBingoDashboardReport.loading = false;
          state.agentBingoDashboardReport.status = true;
        },
      )
      .addCase(
        getAgentBingoDashboardReportAPI.rejected,
        (state: any, action: any) => {
          state.agentBingoDashboardReport.status = false;
          state.agentBingoDashboardReport.loading = false;
          state.agentBingoDashboardReport.error = true;
          state.agentBingoDashboardReport.errorMessage = action.payload;
        },
      );
  },
});

export const {
  updatePasswordChangedStatus,
  udpateAdminPasswordState,
  updateUserNameTakenState,
} = UserSlice.actions;
export default UserSlice.reducer;
