export enum Roles {
  superAdmin = 1,
  Admin,
  MasterAgent,
  Agent,
  Player,
  Cashier,
}

export enum Games {
  KENO = 1,
  BINGO,
  MINI_BINGO,
}
