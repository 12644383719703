import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IStakeState } from "./states/IStakeState";
import AxiosService from "../../../common/services/https.service";
import { backend_url } from "../../../../utils/backend_routes";
import { AxiosError } from "axios";

const initialState: IStakeState = {
  minimumStakes: {
    status: false,
    message: "",
    code: 0,
    data: [],
  },
};

export const getMimimumStakesAPI = createAsyncThunk(
  "getMimimumStakesAPI",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.adminRoute.keno.minimumStakes
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

const stakeSlice = createSlice({
  name: "stakeSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getMimimumStakesAPI.pending, (state: any) => {
        state.minimumStakes.status = false;
        state.minimumStakes.loading = true;
        state.minimumStakes.data = [];
      })
      .addCase(getMimimumStakesAPI.fulfilled, (state: any, action: any) => {
        state.minimumStakes.data = action.payload;
        state.minimumStakes.loading = false;
        state.minimumStakes.status = true;
      })
      .addCase(getMimimumStakesAPI.rejected, (state: any, action: any) => {
        state.minimumStakes.status = false;
        state.minimumStakes.loading = false;
        state.minimumStakes.error = true;
        state.minimumStakes.errorMessage = action.payload;
      });
  },
});

export default stakeSlice.reducer;
