import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IOddTypeState } from "./states/IOddTypeState";
import AxiosService from "../../../common/services/https.service";
import { backend_url } from "../../../../utils/backend_routes";
import { AxiosError } from "axios";

const initialState: IOddTypeState = {
  oddTypes: {
    status: false,
    message: "",
    code: 0,
    data: [],
  },
};

export const getOddTypeAPI = createAsyncThunk(
  "oddTypeAPI",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(backend_url.oddTypes);
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

const oddTypeSlice = createSlice({
  name: "oddTypeSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOddTypeAPI.pending, (state: any) => {
        state.oddTypes.status = false;
        state.oddTypes.loading = true;
        state.oddTypes.data = [];
      })
      .addCase(getOddTypeAPI.fulfilled, (state: any, action: any) => {
        state.oddTypes.data = action.payload;
        state.oddTypes.loading = false;
        state.oddTypes.status = true;
      })
      .addCase(getOddTypeAPI.rejected, (state: any, action: any) => {
        state.oddTypes.status = false;
        state.oddTypes.loading = false;
        state.oddTypes.error = true;
        state.oddTypes.errorMessage = action.payload;
      });
  },
});

export default oddTypeSlice.reducer;
