import { configureStore } from '@reduxjs/toolkit';
import AuthenticationSlice from '../slices/AuthenticationSlice';
import CommonSlice from '../modules/common/slices/CommonSlice';
import UserSlice from '../modules/common/slices/UserSlice';
import AgentSlice from '../modules/admin/keno/slices/AgentsSlice';
import CompanySlice from '../modules/superadmin/slice/CompanySlices';
import RoleSlice from '../modules/admin/keno/slices/RoleSlice';
import OddTypeSlice from '../modules/admin/keno/slices/OddTypeSlice';
import MaxWinAmountSlice from '../modules/admin/keno/slices/MaxWinAmountSlice';
import WinningSequenceSlice from '../modules/admin/keno/slices/WinSequenceSlice';
import CashierSlice from '../modules/admin/keno/slices/CashierSlice';
import StakeSlice from '../modules/admin/keno/slices/StakeSlice';
import GameSlice from '../modules/superadmin/slice/GameSlice';
import CompanyGameSlice from '../modules/admin/keno/slices/CompanyGameSlice';

import BingoShopSlice from '../modules/admin/bingo/slices/BingoShopSlice';
import BingoCashiersSlice from '../modules/admin/bingo/slices/BingoCashierSlice';
import AgentBingoSlice from '../modules/Agents/bingo/slices/AgentBingoSlice';

import ReportSlice from '../modules/admin/keno/slices/ReportSlice';
const store = configureStore({
  reducer: {
    AuthenticationSlice,
    CommonSlice,
    UserSlice,
    AgentSlice,
    CompanySlice,
    RoleSlice,
    OddTypeSlice,
    MaxWinAmountSlice,
    WinningSequenceSlice,
    CashierSlice,
    StakeSlice,
    GameSlice,
    CompanyGameSlice,
    BingoShopSlice,
    BingoCashiersSlice,
    AgentBingoSlice,
    ReportSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
