import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IBingoCashierState } from './states/IBingoCashierState';
import AxiosService from '../../../common/services/https.service';
import { backend_url } from '../../../../utils/backend_routes';
import { AxiosError } from 'axios';

const initialState: IBingoCashierState = {
  bingoCashiers: {
    status: false,
    message: '',
    code: 0,
    data: [],
  },
};

export const getBingoShopCashiersAPI = createAsyncThunk(
  'getBingoShopCashiersAPI',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        backend_url.adminRoute.bingo.bingoCashiers,
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            'Network error is created. Check your network',
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  },
);

const bingoCashierSlice = createSlice({
  name: 'bingoCashierSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBingoShopCashiersAPI.pending, (state: any) => {
        state.bingoCashiers.status = false;
        state.bingoCashiers.loading = true;
        state.bingoCashiers.data = [];
      })
      .addCase(getBingoShopCashiersAPI.fulfilled, (state: any, action: any) => {
        state.bingoCashiers.data = action.payload;
        state.bingoCashiers.loading = false;
        state.bingoCashiers.status = true;
      })
      .addCase(getBingoShopCashiersAPI.rejected, (state: any, action: any) => {
        state.bingoCashiers.status = false;
        state.bingoCashiers.loading = false;
        state.bingoCashiers.error = true;
        state.bingoCashiers.errorMessage = action.payload;
      });
  },
});

export default bingoCashierSlice.reducer;
